<template>
    <UiTabbedSearchboxHero
        :blok="blok"
        :airports="airports"
        :form-actions="[]"
        @submit-form="submitForm"
        @set-active-tab="setActiveTab"
    />
</template>

<script setup lang="ts">
import { formatDate } from '~ui/utils/helpers';
const config = useRuntimeConfig();
import { TABS } from '~ui/components/TabbedSearchbox/constants';
import { useSearchStore } from '../../../apps/_base/store/search';
const searchStore: any = useSearchStore();

const { blok } = defineProps<{ blok: any }>();

const mapTabs = {
    [TABS.AIRPORT_PARKING]: 'Parking',
    [TABS.AIRPORT_LOUNGES]: 'Lounge',
    [TABS.HOTEL_AND_PARKING]: 'HotelWithParking',
};
const activeTab = ref('airport-parking');
const setActiveTab = (tab) => {
    activeTab.value = tab;
    searchStore.updateActiveTab(mapTabs[tab]);
};

const airportsList = [
    { name: 'Aberdeen', code: 'ABZ', id: 18 },
    { name: 'Belfast City', code: 'BHD', id: 33 },
    { name: 'Belfast International', code: 'BFS', id: 11 },
    { name: 'Birmingham', code: 'BHX', id: 12 },
    { name: 'Bournemouth', code: 'BOH', id: 30 },
    { name: 'Bristol', code: 'BRS', id: 3 },
    { name: 'Cardiff', code: 'CWL', id: 13 },
    { name: 'Doncaster', code: 'DSA', id: 21 },
    { name: 'Dover Port', code: 'DOV', id: 24 },
    { name: 'East Midlands', code: 'EMA', id: 4 },
    { name: 'Edinburgh', code: 'EDI', id: 5 },
    { name: 'Exeter', code: 'EXT', id: 19 },
    { name: 'Gatwick', code: 'LGW', id: 16 },
    { name: 'Glasgow', code: 'GLA', id: 6 },
    { name: 'Heathrow', code: 'LHR', id: 7 },
    { name: 'Humberside', code: 'HUY', id: 20 },
    // { name: 'Inverness', code: 'INV', id: 26 }, // removed according to code in original SPS Searchbox
    { name: 'Leeds Bradford', code: 'LBA', id: 14 },
    { name: 'Liverpool', code: 'LPL', id: 8 },
    { name: 'London City', code: 'LCY', id: 23 },
    { name: 'Luton', code: 'LTN', id: 2 },
    { name: 'Manchester', code: 'MAN', id: 1 },
    { name: 'Newcastle', code: 'NCL', id: 15 },
    { name: 'Norwich', code: 'NWI', id: 27 },
    { name: 'Prestwick', code: 'PIK', id: 9 },
    { name: 'Southampton', code: 'SOU', id: 31 },
    { name: 'Southampton Port', code: 'GBSOU', id: 22 },
    { name: 'Southend', code: 'SEN', id: 32 },
    { name: 'Stansted', code: 'STN', id: 10 },
    { name: 'Teesside International', code: 'MME', id: 28 },
];

const airports = { airportList: airportsList, withCountries: false };

function addQueryParamsToUrl(url, params) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => urlObj.searchParams.append(key, params[key]));
    return urlObj.toString();
}

const submitForm = ({ formData, form }) => {
    let query;
    if (activeTab.value === TABS.AIRPORT_PARKING) {
        query = {
            category: 'airportparking',
            airportA: Number(formData.location.id),
            airportA_code: formData.location.code,
            dateA: formatDate(formData.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.dates.end.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.dates.end.date, 'day, dd/mm/yy'),
            timeA: formData.dates.start.time,
            timeB: formData.dates.end.time,
            promo: formData.discount,
            passengers: 1,
            agent: 'ONLINE',
            params: [],
            terminal: formData.terminal.id,
            quoteID: '',
            nohotel: 0,
            park_type: 'all',
        };
    } else if (activeTab.value === TABS.AIRPORT_LOUNGES) {
        query = {
            category: 'airportlounge',
            airportA: Number(formData.location.id),
            airportA_code: formData.location.code,
            dateA: formatDate(formData.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.dates.start.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.dates.start.date, 'day, dd/mm/yy'),
            timeA: formData.dates.start.time,
            timeB: nextTimeSlot(formData.dates.start.time, 60),
            adults: formData.customers?.adult,
            children: formData.customers?.child,
            passengers: 1,
            promo: '',
            agent: 'Lounges',
            params: [],
            terminal: formData.terminal.id,
            quoteID: '',
        };
    }
    const searchUrl = '/booking-quote/' + query.category + '-results';
    navigateTo(addQueryParamsToUrl(config?.public.BOOKING_URL + searchUrl, query), { external: true });
};
</script>

<style lang="postcss" scoped>
:deep(.container) {
    @apply mx-auto;
}
.tabbed-searchbox-hero--hero :deep(.container) {
    @apply mx-auto md:px-8;
}

.tabbed-searchbox-hero--hero:has(+ #hero_footer) {
    @apply lg:pb-[250px];
}

:deep(.num-selector__button) {
    @apply text-[#065988];
}

:deep(.button-primary),
:deep(.search-submit) {
    @apply bg-[#065988] hover:bg-[#F6AB2F] text-white;
}

:deep(.tab-btn) {
    @apply text-[#065988];
    &.is-active {
        @apply bg-[#065988] text-white;
    }
}

:deep(.date-time-picker__icon) {
    @apply mb-0.5;
}

:deep(.vc-highlight-content-solid) {
    @apply !bg-[#05598829];
}

:deep(.search-input input:focus),
:deep(.dropdown.dropdown--active),
:deep(.date-time-picker.date-time-picker--active) {
    outline-color: #055988 !important;
}

:deep(.tabbed-searchbox-hero__title) {
    @apply text-2xl md:text-[54px] font-bold uppercase md:mb-2;
}

:deep(.tabbed-searchbox-hero__subtitle) {
    @apply text-base md:text-xl font-light;
}

:deep(.customers-selector-wrapper__tooltip-icon) {
    @apply -mt-0.5 h-5 w-5;
}
</style>
